<template>
  <div class="flex_row uploadContainer">
    <div class="imgList">
      <div class="imgItem" v-for="img in imgList" :key="img">
        <el-image :src="img" :preview-src-list="[img]" fit="contain" />
        <i class="el-icon-circle-close" @click="deleteImg(img)"></i>
      </div>
      <div class="imgUpload">
        <i class="el-icon-plus"></i>
        <input
          ref="upload"
          type="file"
          accept="image/*"
          capture="user"
          class="imgUpload__input"
          :multiple="true"
          @change="photograph"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import fileService from '@/api/service/file';
  import Sortable from 'sortablejs';

  export default {
    name: 'imgUpload',
    props: {
      cur: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        imgList: []
      };
    },
    watch: {
      cur: {
        handler(val) {
          this.imgList = [...val];
        },
        immediate: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.imgDrop();
      });
    },
    methods: {
      photograph(event) {
        const files = event.target.files;
        let uploadFiles = [];
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > 1024 * 1024 * 3) {
            this.$message({
              type: 'error',
              message: '请上传 3M 以内的文件！'
            });
            break;
          }
          const formdata = new FormData();
          formdata.append('file', files[i]);
          uploadFiles.push(fileService.upload(formdata));
        }
        Promise.all(uploadFiles).then((res) => {
          res.forEach((item) => {
            this.imgList.push(item.filePath);
          });
          this.$emit('input', this.imgList);
        });
        this.$refs.upload.value = null;
      },
      deleteImg(imgUrl) {
        this.imgList = this.imgList.filter((item) => {
          return item !== imgUrl;
        });
        this.$emit('input', this.imgList);
      },
      imgDrop() {
        const imgList = this.$el.querySelector('.imgList');
        Sortable.create(imgList, {
          animation: 180,
          filter: '.imgUpload',
          onEnd: (evt) => {
            const imgList = [...this.imgList];
            this.imgList = [];
            this.$nextTick(() => {
              const currRow = imgList.splice(evt.oldIndex, 1)[0];
              imgList.splice(evt.newIndex, 0, currRow);
              this.imgList = imgList;
              this.$emit('input', this.imgList);
            });
          }
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  .uploadContainer {
    flex-wrap: wrap;
  }
  .imgUpload,
  .imgItem {
    position: relative;
    width: 80px;
    height: 80px;
    margin-right: 16px;
    margin-bottom: 16px;
    text-align: center;
    cursor: pointer;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
  }
  .imgUpload {
    i {
      font-size: 28px;
      line-height: 80px;
      color: #8c939d;
    }
    &__input {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }
  .imgList {
    display: flex;
    flex-wrap: wrap;
  }
  .imgItem {
    .el-image {
      height: 100%;
    }
    i {
      position: absolute;
      right: 0;
      font-size: 24px;
    }
  }
  // .el-upload-list__item {
  //   & > div,
  //   .el-image {
  //     height: 100%;
  //     text-align: center;
  //   }
  //   i {
  //     position: absolute;
  //     right: 0;
  //     font-size: 24px;
  //   }
  // }
</style>
