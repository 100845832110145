<template>
  <dog-dialog
    ref="dogDialog"
    title="设置近期动态"
    @loading-confirm="confirm"
    @closed="closed"
    width="900px"
  >
    <div class="editTrendDialog">
      <div class="single_add" v-if="formData.trend.length < 1">
        <i class="el-icon-circle-plus" @click="addFormData(index)"></i>
      </div>
      <div v-for="(data, index) in formData.trend">
        <div class="add_delete">
          <i class="el-icon-circle-plus" @click="addFormData(index)"></i>
          <i class="el-icon-remove" @click="deleteFormData(index)"></i>
        </div>
        <dog-form
          ref="dogForm"
          :form-data="data"
          :column-num="2"
          :col-rule="(row) => row.col || 12"
          :form-options="formOptions"
        ></dog-form>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import companyService from '@/api/service/company.js';
  import batchImgUpload from '@/components/batchImgUpload.vue';

  export default {
    name: 'editTrendDialog',
    data() {
      return {
        companyId: null,
        formData: {
          trend: [{}]
        },
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '标题',
              prop: 'title',
              rules: [this.$formRules.required('请输入标题')]
            }
          },
          {
            type: 'date',
            formItem: {
              prop: 'date',
              label: '日期',
              rules: [this.$formRules.required('请选择日期')]
            },
            attrs: {
              pickerOptions: {
                disabledDate(time) {
                  return time.getTime() > Date.now();
                }
              }
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'img',
              label: '图片',
              rules: [this.$formRules.required('请上传图片')]
            },
            component: batchImgUpload,
            col: 24
          },
          {
            type: 'input',
            formItem: {
              label: '简介',
              prop: 'desc'
              // rules: [this.$formRules.required('请输入简介')]
            },
            attrs: {
              type: 'textarea',
              rows: 3
            },
            col: 24
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.companyId = id;
        this.$refs.dogDialog.open();
        companyService.getCompanyTrend({ companyId: id }).then((res) => {
          this.formData.trend = res.length === 0 ? [{}] : res;
        });
      },
      addFormData(index) {
        if (this.formData.trend.length >= 20) {
          this.$message({
            type: 'error',
            message: '最多只能添加 20条 动态！'
          });
          return;
        }
        this.formData.trend.splice(index, 0, {});
      },
      deleteFormData(index) {
        this.formData.trend.splice(index, 1);
      },
      confirm(done) {
        const formList = [...this.$refs.dogForm];
        Promise.all(
          formList.map((item) => {
            return item.validate();
          })
        )
          .then(() => {
            return companyService.setCompanyTrend({
              ...this.formData,
              companyId: this.companyId
            });
          })
          .then(() => {
            this.close();
            this.$emit('confirm');
          })
          .finally(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.companyId = null;
        this.formData = { trend: [{}] };
      }
    }
  };
</script>

<style lang="less" scoped>
  .single_add {
    text-align: right;
  }
  .add_delete {
    margin-bottom: 10px;
    text-align: right;
  }
  .el-icon-circle-plus {
    font-size: 25px;
    color: #0080ff;
    cursor: pointer;
  }
  .el-icon-remove {
    margin-left: 5px;
    font-size: 25px;
    color: red;
    cursor: pointer;
  }
</style>
